.chl-blog-button {
  .btn-holder {
    text-align: center;

    a.btn {
      background: #252525;
      padding: 5px 25px;
      color: white;
      border-radius: 0;
      min-width: 150px;

      &:hover {
        background: #424242;
      }
    }
  }
}

.chl-blog-spacer {
  .spacer-15 {
    padding: 15px 0 0;
  }

  .spacer-25 {
    padding: 25px 0 0;
  }
}

.chl-blog-cta {
  .inner {
    background: black;
    color: white;
    padding: 50px 100px;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding: 50px;
    }

    .btn {
      background: #252525;
      color: white;
      padding: 5px 25px;
      margin-top: 25px;
      border-radius: 0;

      &:hover {
        background: #424242;
      }
    }

    h1, h2, h3, h4, h5, p, li {
      color: white;
    }
  }
}

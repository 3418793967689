.chl-blog-col-4 {
  .card-title {
    font-weight: bold;
  }

  .img-holder {
    margin-bottom: 15px;
  }

  .card-holder {
    @media screen and (max-width: 992px) {
      margin-bottom: 25px;
    }
  }

  .card-width {
    max-width: 25%;

    @media screen and (max-width: 992px) {
      width: 100%;
      max-width: none;
    }
  }
}

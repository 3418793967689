.chl-blog-headline {
  background: black;
  padding: 15px;
  text-align: center;
  color: white;

  h1, h2, h3, h4, p, li {
    color: white;
  }
}

.chl-blog-tip {
  background: rgba(245, 245, 245, 1);
  padding: 15px;
  text-align: center;

  :last-child {
    margin: 0;
  }
}

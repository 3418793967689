/* Image Wide */
.chl-blog-img-wide {
  min-height: 400px;
  margin: 0;

  @media screen and (max-width: 767px) {
    min-height: 300px;
  }

  .banner-holder {
    min-height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 767px) {
      min-height: 300px;
    }
  }
}

/* Image Left and Image Right */
.chl-blog-img-left, .chl-blog-img-right {
  .img-holder {
    @media screen and (max-width: 992px) {
      margin-bottom: 25px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

/* Featured Image Left */
.chl-blog-featured-img-left {
  .featured-holder {
    padding: 50px 0;
    background: rgba(245, 245, 245, 1);

    @media screen and (max-width: 992px) {
      padding: 50px 0 30px;
    }
  }

  .featured-item {
    .featured-img-holder {
      @media screen and (max-width: 992px) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }
  }
}

.chl-blog-banner {
  min-height: 600px;
  margin: 0;

  @media screen and (max-width: 767px) {
    min-height: 450px;
  }

  .banner-holder {
    min-height: 600px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    padding-top: 150px;

    &.top-align {
      background-position: top center;
    }

    @media screen and (max-width: 767px) {
      background-position: center;
      min-height: 450px;
      padding-top: 75px;
    }

    .overlay-content {
      margin-top: 200px;
      margin-bottom: 30px;
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: left;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 0 15px;
      width: 60%;

      @media screen and (max-width: 992px) {
        width: 80%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      h1, p {
        color: white;
      }
    }
  }
}
